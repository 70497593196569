import { Icon } from "@lipihipi/rtc-ui-components";
import * as routePaths from "../../RouteConstant";
import { SITE_URL } from "../../constant";


export const MenuArr = [
  {
    id: "0",
    data: [
      {
        id: "0.0",
        icon: Icon.Dashboard,
        text: "Dashboard",
        active: true,
        link: routePaths.DASHBOARD,
      },
      {
        id: "0.1",
        icon: Icon.SProfile,
        text: "Profile",
        active: true,
        link: routePaths.PROFILE,
      },
      {
        id: "0.2",
        icon: Icon.Case,
        text: "Manage Cases",
        active: true,
        link: routePaths.LAWYER_CASES_LIST,
      },
      {
        id: "0.3",
        icon: Icon.UserLead,
        text: "Lead",
        active: true,
        link: routePaths.LAWYER_LEADS,
      },
      {
        id: "0.4",
        icon: Icon.Blog,
        text: "Blogs",
        active: true,
        link: routePaths.LAWYER_BLOGS,
      },
      {
        id: "0.5",
        icon: Icon.Document,
        text: "Document Template",
        active: true,
        link: routePaths.LAWYER_DOCUMENTS_LIST,
      },
      {
        id: "0.6",
        icon: Icon.Appointment,
        text: "Appointments",
        active: true,
        link: routePaths.LAWYER_APPOINTMENTS,
      },
      {
        id: "0.7",
        icon: Icon.Availability,
        text: "Availability",
        active: true,
        child: [
          {
            id: "0.7.1",
            text: "Appointment Schedule",
            link: routePaths.LAWYER_SCHEDULE,
            active: true,
          },
          {
            id: "0.7.2",
            text: "Holiday Calendar",
            link: routePaths.LAWYER_HOLIDAYS,
            active: true,
          },
        ],
      },
      {
        id: "0.8",
        icon: Icon.Lawyer,
        text: "Search Lawyers",
        active: true,
        link: `${SITE_URL}/lawyers`,
        external: true,
      },
      {
        id: "0.9",
        icon: Icon.FreeDocTemplate,
        text: "Free Doc Template",
        active: true,
        link: `${SITE_URL}/legal-documents`,
        external: true,
      },
      {
        id: "0.10",
        icon: Icon.AboutUs,
        text: "About Us",
        active: true,
        link: `${SITE_URL}/about-us`,
        external: true,
      },
    ],
  },
];