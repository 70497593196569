export const DASHBOARD = "/";
export const PROFILE = "/profile";
export const LAWYER_CASES_LIST = "/lawyer/case";
export const LAWYER_CASES_ADD = "/lawyer/case/create";
export const LAWYER_CASES_VIEW = "/lawyer/case/:id";
export const LAWYER_BLOGS = "/lawyer/blog";
export const LAWYER_DOCUMENTS_LIST = "/lawyer/document";
export const LAWYER_DOCUMENTS_ADD = "/lawyer/document/create/:id?";
export const LAWYER_DOCUMENTS_EDIT = "/lawyer/document/edit/:id";
export const LAWYER_APPOINTMENTS = "/lawyer/appointment";
export const LAWYER_VIEW_APPOINTMENT = "/lawyer/appointment/view/:id";
export const LAWYER_SCHEDULE = "/lawyer/appointment/schedule";
export const LAWYER_HOLIDAYS = "/lawyer/appointment/holidays";
export const LAWYER_LEADS = "/lawyer/leads";
export const LAWYER_VIEW_LEAD = "/lawyer/leads/:id";

